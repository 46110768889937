import {
  indexedDBLocalPersistence,
  connectAuthEmulator,
  getAuth,
  setPersistence,
} from "firebase/auth";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import {
  CACHE_SIZE_UNLIMITED,
  connectFirestoreEmulator,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";

const env = import.meta.env;

const config: FirebaseOptions = {
  apiKey: env.REACT_APP_API_KEY,
  appId: env.REACT_APP_APP_ID,
  authDomain: env.REACT_APP_AUTH_DOMAIN,
  databaseURL: env.REACT_APP_FIRESTORE_URL,
  measurementId: env.REACT_APP_MEASUREMENT_ID,
  projectId: env.REACT_APP_PROJECT_ID,
  storageBucket: env.REACT_APP_STORAGE_BUCKET,
};
if (env.REACT_APP_AUTH_DOMAIN?.includes("localhost")) {
  delete config.authDomain;
}

export const _firebase = initializeApp(config);

/**
 * auth setup
 */
export const auth = getAuth(_firebase);
if (env.REACT_APP_AUTH_DOMAIN?.includes("localhost")) {
  connectAuthEmulator(auth, env.REACT_APP_AUTH_DOMAIN, {
    disableWarnings: true,
  });
}
setPersistence(auth, indexedDBLocalPersistence);

/**
 * firestore setup
 */
export const firestore = initializeFirestore(_firebase, {
  ignoreUndefinedProperties: true,
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
    cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  }),
});

if (env.REACT_APP_FIRESTORE_URL?.includes("localhost")) {
  const [, port = 8080] = env.REACT_APP_FIRESTORE_URL.split(":");
  connectFirestoreEmulator(firestore, "127.0.0.1", Number(port));
}

// enableMultiTabIndexedDbPersistence(firestore)
// enableIndexedDbPersistence(firestore, { forceOwnership: true })
//   .then(() => {
//     // eslint-disable-next-line
//     console.log("Enabled offline persistence")
//   })
//   .catch((error) => {
//     if (error.code == "failed-precondition") {
//       // Multiple tabs open, persistence can only be enabled
//       // in one tab at a a time.
//       // ...
//     } else if (error.code == "unimplemented") {
//       // The current browser does not support all of the
//       // features required to enable persistence
//       // ...
//     }
//   });

/**
 * storage setup
 */
export const storage = getStorage(_firebase, env.REACT_APP_STORAGE_BUCKET);
if (env.REACT_APP_STORAGE_URL?.includes("localhost")) {
  const [host = "localhost", port = 9199] = env.REACT_APP_STORAGE_URL.split(":"); // prettier-ignore
  connectStorageEmulator(storage, host, Number(port));
}
