import type {
  RouteMatch,
  DefaultGenerics,
  PartialGenerics,
} from "@tanstack/react-location";

export type PathConfig = {
  private?: boolean;
  // any of the permission bits in the array will satisfy access
  permission?: number | number[];
  feature?: number;
};

export const defaultPageConfig = {
  private: true,
};

export interface TanUseMatch<Params = unknown> extends PartialGenerics {
  LoaderData: Record<string, unknown>;
  Params: { orgId: string; tenantId?: string } & Params;
}

export type LoaderArgs = RouteMatch<DefaultGenerics>;
