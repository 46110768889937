// routes
import { Routes } from "./router/file-routing";

// contexts
import AllContexts from "./contexts/root";

// components
import { ConfirmationModal } from "./components/ConfirmationModal";

const App: React.FC = () => {
  return (
    <AllContexts>
      <Routes />
      <ConfirmationModal />
    </AllContexts>
  );
};

export default App;
