// libraries
import { fetchAndActivate, getAll } from "firebase/remote-config";

// services
import { remoteConfig } from "../services/firebase.service";
import {
  deriveFeatureFlag,
  parseRemoteFetch,
  RemoteConfigType,
  alwaysOnFeatures,
  defaultConfig,
} from "wombat-global/src/config";

export class WombatConfig {
  config: RemoteConfigType;
  activeFlags: number;
  defaultOnFlags: number;

  constructor() {
    remoteConfig.settings.fetchTimeoutMillis = 60000;
    remoteConfig.defaultConfig = defaultConfig;
    this.config = defaultConfig;
    this.activeFlags = deriveFeatureFlag(this.config);
    this.defaultOnFlags = deriveFeatureFlag(alwaysOnFeatures);

    fetchAndActivate(remoteConfig).then(() => {
      this.config = parseRemoteFetch(this.config, getAll(remoteConfig));
      this.activeFlags = deriveFeatureFlag(this.config);
    });
  }
}
const wombatConfig = new WombatConfig();

export default wombatConfig;
