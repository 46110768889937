// MUI
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useMatch, useNavigate } from "@tanstack/react-location";

import { ReactComponent as ComingSoonLogo } from "../assets/coming_soon.svg";

const ErrorComponent: React.FC = () => {
  const navigator = useNavigate();
  const toSafety = () => navigator({ to: "/" });
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box sx={{ opacity: 0.5 }}>
        <ComingSoonLogo />
      </Box>

      <Typography variant="h4" sx={{ mb: 2 }}>
        <b>Uh Oh</b>, Looks like some error happened.
      </Typography>
      <Button onClick={toSafety}> Take me to safety </Button>
    </Box>
  );
};

export default ErrorComponent;
