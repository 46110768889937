// Libraries
import { useCallback, useState, useEffect } from "react";

// MUI
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import Report from "@mui/icons-material/Report";
import Warning from "@mui/icons-material/Warning";

// hooks
import { useStore } from "../contexts/dataStore.context";

// types
type ConfirmationActionType = {
  label: string;
  type?: "error" | "warning" | "success" | "info";
  focused?: boolean;
  onClick?: () => void;
};

export type ConfirmationModalType = {
  open: boolean;
  type?: "warning" | "danger";
  title?: string | React.ReactElement;
  content?: string | React.ReactElement;
  actions: ConfirmationActionType[];
};

export const ConfirmationModal: React.FC = () => {
  const [modal] = useStore<ConfirmationModalType>("confirmationModal");
  const [open, setOpen] = useStore<boolean>("confirmationModal.open");
  const [error, setModalError] = useState<unknown>();

  const [disabled, setDisabled] = useState(false);

  /**
   * Functions
   */
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (!open && error) {
      setModalError(undefined);
    }
  }, [error, open]);

  return (
    <Dialog
      open={open}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      PaperProps={{
        sx: { pt: 1 },
      }}
    >
      {modal.type === "warning" && (
        <div className="flex f-c-c mt-16 mb-16">
          <Warning
            fontSize="large"
            sx={{ color: "var(--color-chart--warning--dark)" }}
          />
          <Typography variant="h3" sx={{ mx: 1, mt: 1 }}>
            Warning
          </Typography>
        </div>
      )}
      {modal.type === "danger" && (
        <div className="flex f-c-c mt-16 mb-16">
          <Report
            fontSize="large"
            sx={{ color: "var(--color-chart--overdue--dark)" }}
          />
          <Typography variant="h3" sx={{ mx: 1, mt: 1 }}>
            Danger
          </Typography>
        </div>
      )}
      {typeof modal?.title === "string" ? (
        <DialogTitle
          sx={{ textAlign: "center", color: "var(--color-text--secondary)" }}
          id="confirmation-dialog-title"
        >
          {modal?.title}
        </DialogTitle>
      ) : (
        modal.title
      )}
      <DialogContent>
        <>
          <DialogContentText
            component="div"
            style={{ textAlign: "center" }}
            id="confirmation-dialog-description"
          >
            {modal?.content}
          </DialogContentText>
          {error && (
            <DialogContentText
              component="div"
              color="error"
              sx={{ mt: 3, textAlign: "center" }}
            >{`${error}`}</DialogContentText>
          )}
        </>
      </DialogContent>
      <DialogActions sx={{ flexWrap: "wrap", justifyContent: "center" }}>
        {modal.actions.map((action, key) => (
          <Button
            disabled={disabled}
            key={`${action.label}.${key}`}
            variant="text"
            onClick={async () => {
              setDisabled(true);
              try {
                if (action?.onClick) {
                  await action.onClick();
                }
                handleClose();
                // eslint-disable-next-line
              } catch (err: any) {
                if (typeof err === "string") {
                  setModalError(err);
                } else if (typeof err === "object") {
                  setModalError(err.message);
                } else {
                  setModalError(err.toString());
                }
              } finally {
                setDisabled(false);
              }
            }}
            autoFocus={action?.focused}
            color={action?.type || "primary"}
          >
            {action?.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};
