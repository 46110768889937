import { LoadingProgress } from "./LoadingProgress";

const LoadingWrapper: React.FC<React.PropsWithChildren<{ when: boolean }>> = ({
  when,
  children,
}) => {
  if (when) {
    return <LoadingProgress />;
  }
  return <>{children}</>;
};

export default LoadingWrapper;
