import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: true,
      retryDelay: 1000,
      retry(failureCount, error) {
        if (failureCount > 2) {
          // eslint-disable-next-line no-console
          console.error(error);
          return false;
        }
        return true;
      },
      retryOnMount: true,
    },
  },
});
