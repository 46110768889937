// libraries
import React from "react";
import {
  onSnapshot as fbOnSnapshot,
  doc as fbDoc,
  query as fbQuery,
  collection as fbCollection,
  limit as fbLimit,
  orderBy as fbOrderBy,
  where as fbWhere,
  startAfter as fbStartAfter,
} from "firebase/firestore";

// services
import { firestore } from "../../services/firebase.service";

// Contexts
import { SubscriptionContext } from "./subscriptions.context";

// types
import {
  WhereType,
  OrderByType,
  SubscriptionStateManagement,
  UnsubscribeFunction,
  FirestoreCollectionSnapshotProps,
} from "./subscription.types";

export const defaultLimit = 10;

/**
 * Subscription Provider is a central way to manage application api Subscriptions
 * It:
 *   - Registers a sub (firestore path) and on updates saves the result into context state
 *   - Hooks are then used to listen to new or already registered subs.
 *
 * This means that:
 *   - Subs are unregistered on page refresh only, not component mount.
 *   - Multiple components registering the same listener will be listening to the same data (and only one sub)
 */
type TProps = { children: React.ReactNode };

// all operators but "==" and "in"
// this handles one of the limitations of firebase query
// see reason below
export const operatorsNeedOrderBy = ["<", "<=", ">", ">=", "!=", "not-in"];

export class SubscriptionsProvider extends React.Component<TProps> {
  // map of unSubscription callback functions
  private unSubHash: Record<string, UnsubscribeFunction | undefined> = {};

  // subscription data storage state
  public state: SubscriptionStateManagement = {};

  constructor(props: TProps) {
    super(props);
    this.state = {};
    this.cleanSubscriptions = this.cleanSubscriptions.bind(this);
    this.removeFirestoreSub = this.removeFirestoreSub.bind(this);
    this.addFirestoreSnapshot = this.addFirestoreSnapshot.bind(this);
    this.addFirestoreCollectionSnapshot = this.addFirestoreCollectionSnapshot.bind(this); // prettier-ignore
  }

  /**
   * Add firestore subscription
   */
  public addFirestoreSnapshot(path: string): void {
    if (this.unSubHash[path]) {
      return;
    }
    const unSubFcn = fbOnSnapshot(fbDoc(firestore, path), {
      next: (value) => {
        this.setState(
          (
            prevState: SubscriptionStateManagement,
          ): SubscriptionStateManagement => {
            const currentSubState = prevState[path];
            return {
              ...prevState,
              [path]: {
                ...(currentSubState ? currentSubState : {}),
                error: null,
                updatedAt: new Date().getTime(),
                value: value.data(),
                res: value,
                state: "initialized",
              },
            };
          },
        );
      },
      error: (error) => {
        this.setState(
          (
            prevState: SubscriptionStateManagement,
          ): SubscriptionStateManagement => {
            return {
              ...prevState,
              [path]: {
                ...(prevState[path] ? prevState[path] : {}),
                updatedAt: new Date().getTime(),
                error: error,
                state: "errored",
              },
            };
          },
        );
      },
      complete: () => {
        this.setState(
          (
            prevState: SubscriptionStateManagement,
          ): SubscriptionStateManagement => {
            return {
              ...prevState,
              [path]: {
                ...(prevState[path] ? prevState[path] : {}),
                updatedAt: new Date().getTime(),
                state: "completed",
              },
            };
          },
        );
      },
    });
    this.unSubHash[path] = unSubFcn;
  }

  // TODO; removal here can be done in a smarter way
  // (queue unsub so its removal is delayed reducing the bounce reads)

  /**
   * Remove the firestore document subscription
   * @param path string
   */
  public removeFirestoreSub(path: string): void {
    const fcn = this.unSubHash[path];
    if (fcn) {
      fcn();
      this.unSubHash[path] = undefined;
      delete this.state[path];
    }
    // eslint-disable-next-line
    console.log("current SubscriptionStates", this.state);
  }

  /**
   * get firestore collection snapshot
   * @param path path to collection
   * @returns
   *
   * state = {
   *   [path:orderby:]: dataState1, doc 1-5
   *   [path:orderby:startAfter1]: dataState2, doc 6-10
   *   [path:orderby:startAfter2]: dataState3, doc 11-15
   * }
   */
  public addFirestoreCollectionSnapshot({
    path,
    orderBy,
    where,
    queryLimit = defaultLimit,
    startAfter,
  }: FirestoreCollectionSnapshotProps) {
    let req;
    let key: string;
    if (typeof path === "string") {
      key = path;
      req = fbQuery(fbCollection(firestore, path));
    } else {
      key = path.key;
      req = path.ref;
    }

    // each search should be saved in browser memory, so cached
    // thats so navigation to and from a page will not re-query
    const pathHash = this.encodeKey(key, orderBy, where, startAfter?.id);

    if (this.unSubHash[pathHash]) {
      return;
    }

    req = fbQuery(req, fbLimit(queryLimit));

    if (
      where?.length &&
      where.some((whr) => operatorsNeedOrderBy.includes(whr.opStr))
    ) {
      // handle one of the firebase limitations
      // "If you include a filter with a range comparison (<, <=, >, >=, not-in),
      // your first ordering must be on the same field"
      // https://firebase.google.com/docs/firestore/query-data/order-limit-data#limitations
      req = fbQuery(req, ...where.map((whr) => fbOrderBy(whr.fieldPath)));
    }

    if (orderBy?.length) {
      req = fbQuery(
        req,
        ...orderBy.map((order) =>
          fbOrderBy(order.fieldPath, order.directionStr),
        ),
      );
    }

    if (where?.length) {
      req = fbQuery(
        req,
        ...where.map((whr) => fbWhere(whr.fieldPath, whr.opStr, whr.value)),
      );
    }

    if (startAfter) {
      req = fbQuery(req, fbStartAfter(startAfter));
    }

    const unSubFcn = fbOnSnapshot(req, {
      next: (value) => {
        this.setState(
          (
            prevState: SubscriptionStateManagement,
          ): SubscriptionStateManagement => {
            const currentSubState = prevState[pathHash];
            return {
              ...prevState,
              [pathHash]: {
                ...(currentSubState ? currentSubState : {}),
                updatedAt: new Date().getTime(),
                value: value.docs.map((document) => document.data()),
                // This result is needed for accessing the last firebase document that was returned
                res: value,
                error: null,
                state: "initialized",
              },
            };
          },
        );
      },
      error: (error) => {
        this.setState(
          (
            prevState: SubscriptionStateManagement,
          ): SubscriptionStateManagement => {
            return {
              ...prevState,
              [pathHash]: {
                ...(prevState[pathHash] ? prevState[pathHash] : {}),
                updatedAt: new Date().getTime(),
                error: error,
                state: "errored",
              },
            };
          },
        );
      },
      complete: () => {
        this.setState(
          (
            prevState: SubscriptionStateManagement,
          ): SubscriptionStateManagement => {
            return {
              ...prevState,
              [pathHash]: {
                ...(prevState[pathHash] ? prevState[pathHash] : {}),
                updatedAt: new Date().getTime(),
                state: "completed",
              },
            };
          },
        );
      },
    });
    this.unSubHash[pathHash] = unSubFcn;
  }

  /**
   * Clear all subscriptions from store
   */
  public cleanSubscriptions(): void {
    Object.keys(this.state).forEach((path) => {
      this.removeFirestoreSub(path);
    });
  }

  encodeKey(
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    order?: OrderByType[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    whereFilter?: WhereType[],
    startAfterId?: string,
  ): string {
    return btoa(
      [
        path,
        (order || []).map((od) => JSON.stringify(od)).join(":"),
        (whereFilter || []).map((w) => JSON.stringify(w)).join(":"),
        startAfterId || "",
      ].join("::"),
    );
  }

  /**
   * Remove Sub
   */
  // TODOs:
  // we should have a strategy for removing subscriptions from the FE
  // - keep 100 active
  // - base it off likely hood to came back
  // **- accept retention policy from initializer

  render(): JSX.Element {
    return (
      <SubscriptionContext.Provider
        value={{
          state: this.state,
          removeFirestoreSub: this.removeFirestoreSub,
          addFirestoreSnapshot: this.addFirestoreSnapshot,
          addFirestoreCollectionSnapshot: this.addFirestoreCollectionSnapshot,
          cleanSubscriptions: this.cleanSubscriptions,
          encodeKey: this.encodeKey,
        }}
      >
        {this.props.children}
      </SubscriptionContext.Provider>
    );
  }
}
