// libraries
import { Navigate, AsyncElement } from "@tanstack/react-location";

// services
import { auth } from "../services/firebase.service";
import {
  hasWombatStaffAdminPermissions,
  hasWombatStaffPermissions,
} from "wombat-global/src/utilities";
import { READ } from "wombat-global/src/permission.maps";

export default function routeElement(
  Component?: React.ReactNode,
): AsyncElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async function innerAsyncFunction(match): Promise<React.ReactNode> {
    // eslint-disable-next-line no-console
    console.time("auth-loading");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,
    while (!(auth as any)["_isInitialized"]) {
      await new Promise((res) => setTimeout(res, 25));
    }
    // eslint-disable-next-line no-console
    console.timeEnd("auth-loading");

    const token = await auth.currentUser?.getIdTokenResult();

    if (
      !token ||
      (!hasWombatStaffAdminPermissions(token.claims) &&
        !hasWombatStaffPermissions(token.claims, READ.bitFlag))
    ) {
      return <Navigate to="/login" />;
    }

    return (
      Component ?? (
        <div>Looks like you don`t have access to any org or tenant</div>
      )
    );
  };
}
