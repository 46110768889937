import React from "react";
import { createRoot } from "react-dom/client";

// MainApp
import App from "./App";

import "./services/firebase.service";
import "./scss/index.scss";

const container = document.getElementById("root");

const currentTheme = localStorage.getItem("theme");
if (currentTheme === "light") {
  document.querySelector("html")?.classList.remove("dark-theme");
} else {
  document.querySelector("html")?.classList.add("dark-theme");
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
