// libraries
import { useMemo } from "react";
import { Navigate, useMatch } from "@tanstack/react-location";

// hook
import { useFeatureFlags } from "../hooks/useFeatureFlags";
import { useAuthenticationContext } from "../contexts/authentication/authentication.context";

import { featureIsEnabled } from "wombat-global/src/feature-flags.maps";

// types
import { PathConfig } from "./types";
import { LoadingProgress } from "../components/LoadingProgress";

export function Auth({
  children,
  config,
}: {
  children: React.ReactNode;
  config?: PathConfig;
}): JSX.Element {
  const { isAuthenticated, initialized, hasPermission } =
    useAuthenticationContext();
  const redirectTo = useMatch();

  /**
   * feature flags rely on org and tenant data to be loaded
   */
  const [featureFlags, flagsLoading] = useFeatureFlags();
  const featureEnabled = config?.feature
    ? featureIsEnabled(featureFlags, config?.feature)
    : true;

  const userHasPermission = useMemo(() => {
    /**
     * pages with a specific required permission bit
     */
    if (config?.permission) {
      return (
        typeof config.permission === "number"
          ? [config.permission]
          : config.permission
      ).some((perm) => hasPermission(perm));
    } else if (config?.private) {
      return isAuthenticated;
    }

    // else its public
    return true;
  }, [config, hasPermission, isAuthenticated]);

  /**
   * renders
   */
  if (initialized && !isAuthenticated) {
    return <Navigate to={`/login?redirectTo=${redirectTo.pathname}`} />;
  }

  if (config?.feature !== undefined && flagsLoading) {
    return <LoadingProgress />;
  }

  if (initialized && !userHasPermission) {
    return <Navigate to={"/404"} />;
  }

  if (config?.feature !== undefined && !featureEnabled) {
    return <Navigate to={"/feature-error"} />;
  }

  return <>{children}</>;
}
