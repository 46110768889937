// libraries
import { featureIsEnabled } from "wombat-global/src/feature-flags.maps";

// constants && types
import config from "../config";
import { IPermission } from "wombat-global/src/permission.maps";

export function useFeatureFlags(): [number, boolean];

export function useFeatureFlags(
  ...flagFilter: IPermission[]
): [boolean, boolean];

export function useFeatureFlags(
  ...flagFilter: IPermission[]
): [number, boolean] | [boolean, boolean] {
  // This override should only allow true => false conversion. If global hardcoded flag is false then that toggle is disabled
  // BitFags logic is, G & O (org true flags) and G & T (tenant true flags)

  // override global with org, and org with tenant (only override true with false). I guess thats filtering.

  const flags = config.defaultOnFlags;
  if (flagFilter.length) {
    flagFilter.every((flag) => featureIsEnabled(flags, flag.bitFlag));

    return [
      flagFilter.every((flag) => featureIsEnabled(flags, flag.bitFlag)),
      false,
    ];
  }

  return [flags, false];
}

export function useGlobalFeatureFlags(...flagFilter: IPermission[]): boolean {
  return flagFilter.every((flag) =>
    featureIsEnabled(config.defaultOnFlags, flag.bitFlag),
  );
}
