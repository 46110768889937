// libraries
import { useCallback, useMemo } from "react";
import { useMatch, useNavigate, useMatchRoute } from "@tanstack/react-location";

// hooks
import { useParams } from "./useParams";
import { regularRoutes, routeMap } from "../router/utilities";

/**
 * this hook returns the routes defined in the constants/routes file but with the current org and tenant parameters set.
 */
export function useRoutePaths(): Record<keyof typeof routeMap, string> {
  const currentParams = useParams();

  const currentRoutes = useMemo(() => {
    return regularRoutes.reduce<Record<keyof typeof routeMap, string>>(
      (routes, key) => {
        Object.keys(currentParams).forEach((k) => {
          routes[key] = (routes[key] || key).replace(`:${k}`, currentParams[k]);
        });
        routes[key] = `/${routes[key]}`;
        return routes;
      },
      {},
    );
  }, [currentParams]);

  return currentRoutes;
}

/**
 * @deprecated
 * @returns
 */
export function useOnPageLoad<T = Record<string, string>>(): T {
  const routerMatch = useMatch();

  return routerMatch.params as unknown as T;
}

interface ContextualRoutingHook {
  goTo: (
    path: keyof typeof routeMap,
    params?: Record<string, string | undefined>,
  ) => void;
  isActive: (path: string) => boolean;
}

export function useContextualRouting(): ContextualRoutingHook {
  const currentParams = useParams();
  const matchRoute = useMatchRoute();
  const navigate = useNavigate();
  const routes = useRoutePaths();

  /**
   * Derived Properties
   */
  const goTo = useCallback(
    (path: keyof typeof routeMap, params = {}) => {
      let to = path;
      const p = { ...currentParams, ...params };
      Object.keys(p).forEach((k) => {
        to = to.replace(`:${k}`, p[k]);
      });
      navigate({ to });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [routes],
  );

  const isActive = useCallback(
    (path: string) => {
      return Boolean(matchRoute({ to: path }));
    },
    [matchRoute],
  );

  /**
   * hook api
   */
  const api = useMemo(() => ({ goTo, isActive }), [goTo, isActive]);

  return api;
}
